import React, { useEffect, useState } from 'react'
import { graphql, type HeadFC, type PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { css } from '../../../styled-system/css'
import Masonry from 'react-responsive-masonry'
import SEO from '../../components/SEO'

const getWidth = () =>
  typeof window !== 'undefined'
    ? window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    : 0

const GalleryPage: React.FC<PageProps> = ({ data }) => {
  const queryData = data as Query
  const [colsAmount, setColsAmount] = useState(1)
  let [width, setWidth] = useState(getWidth())
  const [activeCategory, setActiveCategory] = useState<string | null>(null)
  const [storedPageId, setStoredPageId] = useState<string | null>('') // State to hold the retrieved ID

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const resizeListener = () => {
        const newWidth = getWidth()
        setWidth(newWidth)
        setColsAmount(newWidth > 900 ? 3 : newWidth > 600 ? 2 : 1)
      }
      window.addEventListener('resize', resizeListener)
      resizeListener()
      return () => {
        window.removeEventListener('resize', resizeListener)
      }
    }
  }, [])

  useEffect(() => {
    // Retrieve the stored ID from localStorage
    const storedPageId = localStorage.getItem('pageId')
    // Only set the active category if storedPageId is not null and not empty
    if (storedPageId && storedPageId.trim() !== '') {
      setActiveCategory(storedPageId)
      localStorage.removeItem('pageId')
    }
  }, [])

  const categorys = queryData.allContentfulPortfolioImages.edges.map(
    ({ node }) => {
      return node.category
    }
  )

  const unduplicatedCategorys = [...new Set(categorys)]

  const handleCategoryClick = (category: string) => {
    if (activeCategory === category) {
      return setActiveCategory(null)
    }

    setActiveCategory(category)
  }

  const correctImagesToDisplay =
    activeCategory === null
      ? queryData.allContentfulPortfolioImages.edges
      : queryData.allContentfulPortfolioImages.edges.filter(
          ({ node }) => node.category === activeCategory
        )

  return (
    <HeaderAndFooterLayout>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          flexDir: 'column',
          p: '20px 0',
          gap: '20px',
          maxW: '1700px',
          margin: 'auto',
        })}
      >
        <div
          className={css({
            overflow: 'hidden',
            width: '100%',
          })}
        >
          <ul
            className={css({
              display: 'flex',
              gap: '5px',
              flexWrap: 'no-wrap',
              flexDirection: 'row',
              p: '5px',
              justifyContent: 'center',
              overflowX: 'scrol',
            })}
          >
            {unduplicatedCategorys.map((category, idx) => (
              <li
                style={
                  activeCategory === category
                    ? {
                        color: 'black',
                        opacity: '1',
                        borderBottom: '3px solid black',
                      }
                    : {}
                }
                className={css({
                  p: '10px',
                  cursor: 'pointer',
                  userSelect: 'none',
                  color: 'grey',
                  opacity: '.5',
                  transition: '.3s',
                  pos: 'relative',
                  borderBottom: '3px solid transparent',
                })}
                key={idx}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        <Masonry columnsCount={colsAmount} gutter="5px">
          
          {correctImagesToDisplay.map(({ node }) => {
            return (
              <>
                {node.image ? (
                  <GatsbyImage
                    key={node.id}
                    className={css({
                      w: '100%',
                    })}
                    image={
                      getImage(node.image.gatsbyImageData) as IGatsbyImageData
                    }
                    alt={node.image.title || 'Portfolio Image'}
                  />
                ) : null}
              </>
            )
          })}
        </Masonry>
      </div>
    </HeaderAndFooterLayout>
  )
}

export const query = graphql`
  query MyQuery {
    allContentfulPortfolioImages {
      edges {
        node {
          id
          category
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
            title
            filename
          }
        }
      }
    }
  }
`

type Query = {
  allContentfulPortfolioImages: {
    edges: {
      node: {
        id: string
        category: string
        image: {
          gatsbyImageData: IGatsbyImageData
          filename: string
          title: string
        }
      }
    }[]
  }
}

export default GalleryPage

export const Head: HeadFC = () => (
  <SEO
    title="Galerie photo Digit Universe Photographie - Découvrez nos prestations photographiques"
    description="Explorez la galerie photo de Digit Universe Photographie à Lille. Découvrez nos prestations en photographie de grossesse, nouveau-nés, familles, couples, et événements. Immortalisez vos souvenirs inoubliables avec créativité et passion."
    robotsContent="index, follow"
  />
)
